.Blog-Image {
  height: 300px;
}

.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
  background-color: #666666;
}

input {
  width: 100%;
}

textarea {
  width: 100%;
}

select {
  width: 100%;
}

.clr-select-wrapper {
  width: 100%;
}

.text-full-width {
  width: 100%;
}

.large-img {
  width: 750px;
}

.row {
  margin-top: 20px;
}

.pad-top {
  padding-top: 15px;
}

.photo-share-img {
  max-height: 192px;
  width: auto;
}

/*.container {
  color: lightgray;
  background-color: rgb(26, 33, 40)
}

.table {
  color: lightgray;
}

.main-container {
  background-color: rgb(26, 33, 40)
}

.card {
  color: black;
}*/