pre {
  background-color: lightgray;
}

.App-logo {
  width: 75px;
}

.clr-textarea {
  width: 100%;
}

.text-full-width {
  width: 100%;
}